export const getNumberWithOrdinalWithSuperscript = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return (
    <span>
      {n}
      <sup>{s[(v - 20) % 10] || s[v] || s[0]}</sup>
    </span>
  );
};
