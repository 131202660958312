import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactDOM from 'react-dom';
import './index.css';
import CityEmbed from "./components/city/city";
import AllEmbed from "./components/all/all";
import CompareEmbed from "./components/compare/compare";
// import MapEmbed from "./components/map/map";
import qs from "qs";

function getEl(embedSelector) {
    if (typeof embedSelector === "string") {
        const el = document.querySelector(embedSelector);
        // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
        if (!el) return;
        return el;
    } else {
        return embedSelector;
    }
}

function getEls(embedSelector) {
    if (typeof embedSelector === "string") {
        const els = document.querySelectorAll(embedSelector);
        // if (!els) throw new Error(`No divs matching selector "${embedSelector}"`);
        if (!els) return;
        return els;
    } else {
        return embedSelector;
    }
}

function getUrlParams() {
    const params = qs.parse(window.location.search.slice(1));

    return {
        type: params.embed,
        city: params.city,
    };
}

window.addEventListener("DOMContentLoaded", () => {
    const params = getUrlParams();
    
    if (params) {
        // console.log("Embed params");
        // console.table(params);

        [...getEls("[data-citycountry-embed]")].forEach((e) => {
            // ReactDOM.render(<CityEmbed {...params} citySlug={e.getAttribute("data-citycountry-embed")}/>, e);
            const root = createRoot(e);
            root.render(<CityEmbed {...params} citySlug={e.getAttribute("data-citycountry-embed")} />);
        })
        if (getEl("[data-all-embed]")) {
            // ReactDOM.render(<AllEmbed {...params} />, getEl("[data-all-embed]"));
            const container = getEl("[data-all-embed]");
            const root = createRoot(container); //
            root.render(<AllEmbed />);
        }
        if (getEl("[data-compare-embed]")) {
            // ReactDOM.render(<CompareEmbed {...params} />, getEl("[data-compare-embed]"));
            const container = getEl("[data-compare-embed]");
            const root = createRoot(container); //
            root.render(<CompareEmbed />);
        }
        // if (getEl("[data-map-embed]")) {
        //     // ReactDOM.render(<MapEmbed {...params} />, getEl("[data-map-embed]"));
        //     const container = getEl("[data-map-embed]");
        //     const root = createRoot(container); //
        //     root.render(<MapEmbed />);
        // }
    }
});
