import axios from "axios";
import React from "react";
import { DATA_SHEET_URL, SCORES_SHEET_URL } from "../config/constants";

const useCitiesData = () => {
    const [citiesData, setCitiesData] = React.useState(null);
    const [scoreColumns, setScoreColumns] = React.useState(null);

    React.useEffect(() => {
        Promise.all([
            axios(DATA_SHEET_URL)
                .then(res => res.data)
                .then(setCitiesData),

            axios(SCORES_SHEET_URL)
                .then(res => {
                    return Object.values(res.data).map((d) => {
                        return {
                            column: d["score_columns"],
                            name: d["score_name"],
                            icon: d.icon,
                            color: d.color
                        }
                    })
                })
                .then(setScoreColumns)
        ]);
    }, []);

    return {
        citiesData,
        scoreColumns
    }
};

export default useCitiesData;
