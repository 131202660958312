import React from "react";
import useCitiesData from "../../hooks/use-cities-data";
import styles from "./city.module.scss";
import { useIsMobile } from "../../hooks/mobile.js";
import { getNumberWithOrdinalWithSuperscript } from "../../hooks/utils.js";

export default function CityEmbed(props) {
  const { citySlug } = props;
  const { citiesData, scoreColumns } = useCitiesData();
  let city = null;
  let cityData = null;

  const isMobile = useIsMobile();

  const ICON_SIZE = isMobile ? 30 : 36;

  if (citiesData) {
    city = citiesData
      .map((d) => {
        return {
          ...d,
          city: d.city ? d.city : d.country,
        };
      })
      .find((d) => d.slug === citySlug);
  }
  if (city && scoreColumns) {
    cityData = {
      total: city.totalScore,
      scores: scoreColumns.map((d) => {
        return {
          score: d.column,
          name: d.name,
          icon: d.icon,
          value: city[d.column],
        };
      }),
    };
  }

  return (
    <div className={styles.cityEmbed}>
      {cityData && (
        <table className={styles.cityTable}>
          <thead>
            <tr className={styles.cityTableHead}>
              <th colSpan={isMobile ? 2 : 3}>
                <h2 className={styles.cityTableHeader}>
                  {getNumberWithOrdinalWithSuperscript(city.rank)}
                  {" " + city.countryDisplay}
                </h2>
              </th>
              <th colSpan={isMobile ? 2 : 1} className={styles.totalScore}>
                <span className={styles.cityTableHeaderScore}>Total Score</span>
                <h2 className={styles.cityTableHeaderH2}>
                  {cityData.total.toFixed(0)}
                </h2>
              </th>
            </tr>
          </thead>
          <tbody>
            {cityData.scores.map((d) => {
              return (
                <tr key={d.name}>
                  <td aria-hidden="true" className={styles.iconColumn}>
                    <img
                      src={"data:image/svg+xml," + encodeURIComponent(d.icon)}
                      alt={d.score}
                      className={styles.parameterIcon}
                      style={{ height: "auto", width: ICON_SIZE }}
                      aria-hidden="true"
                    />
                  </td>
                  <td className={styles.nameColumn}>{d.name}</td>
                  <td aria-hidden="true" className={styles.barchartColumn}>
                    <div className={styles.barchartBackground}></div>
                    <div
                      className={styles.barchartForeground}
                      style={{ width: `${(d.value / 10) * 100}%` }}
                    ></div>
                    <div className={styles.barChartLabel}>{d.name}</div>
                  </td>
                  <td className={styles.scoreColumn}>{d.value.toFixed(0)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
